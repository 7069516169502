@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

//color palette
$primary-color: #357db0;
$secondary-color: #dbe7ff;

$primary-blue: #04a5e2;
$primary-green: #19be87;
$primary-red: #f16969;

$primary-white: #ffffff;
$secondary-white: #f1f0f0;

$label-color: #425465;

$placeholder-color: #afafaf;

$paragraph-text: #323232;

$border-color: #edf2f7;

$dark: #212529ff;
$light: #f8f9faff;
$body-bg: #f8f9faff;

//box-shadow
$card-box-shadow: 6px 0 0
  rgba(
    $color: $paragraph-text,
    $alpha: 30%,
  );

//breakpoints
$desktop-breakpoint: 1280px;
$large-tablet-breakpoint: 992px;
$tablet-breakpoint: 768px;

//mixin for different screen sizes
@mixin tablet {
  @media screen and (min-width: $tablet-breakpoint) {
    @content;
  }
}

@mixin large-tablet {
  @media screen and (min-width: $large-tablet-breakpoint) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: $desktop-breakpoint) {
    @content;
  }
}

a {
  text-decoration: none;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

img {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
