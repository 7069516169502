@use "./globals" as *;

.payment {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem 7vw;
  color: $paragraph-text;

  &__container {
    display: flex;
    flex-direction: column;
  }

  &__cards-container {
    display: flex;
    flex-direction: row;

    @include large-tablet {
      flex-direction: row;
    }
  }

  &__cards {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    width: 100%;

    @include large-tablet {
      margin-right: 2rem;
    }
  }

  &__switch {
    position: relative;
    display: inline-block;
    width: 15rem;
    background-color: $primary-white;
    border-radius: 2rem;
    border: 2px solid $border-color;
    overflow: hidden;
    transition: background-color 0.5s;

    &-button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 100%;
      width: 100%;
      background-color: transparent;
      cursor: pointer;
      outline: none;
      border: none;
    }

    &-slider {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 50%;
      background-color: $primary-color;
      border-radius: 2rem;
      transition: transform 0.5s, background-color 0.5s;
    }

    &-monthly,
    &-yearly {
      height: 100%;
      width: 100%;
      padding: 0.5rem;
      z-index: 1;
      transition: color 0.5s;
    }

    &-monthly {
      color: $primary-white;
    }

    &--yearly {
      & .payment__switch-slider {
        transform: translateX(100%);
      }

      & .payment__switch-yearly {
        color: $primary-white;
      }

      & .payment__switch-monthly {
        color: $paragraph-text;
      }
    }
  }
}

.payment-card {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 380px;
  background-color: $primary-white;
  padding: 1rem 2rem;
  border: 2px solid $border-color;
  height: 500px;
  border-radius: 1rem;
  margin: 1rem 0;
  cursor: pointer;
  transition: all 0.5s ease;
  gap: 2rem;

  &:hover {
    box-shadow: 0px 4px 6px $border-color;
  }

  &--selected {
    background-color: $primary-color;
    color: $primary-white !important;
  }

  &--selected &__price {
    color: $primary-white;
  }

  &--selected &__label {
    color: $primary-white;
  }

  &--selected &__plan-card {
    color: #000;
  }

  &__price {
    font-weight: 700;
    color: $paragraph-text;
  }

  &__label {
    color: $placeholder-color;
    margin: 0 0.5rem;
  }

  &__disclaimer {
    position: absolute;
    bottom: 0.5rem;
    right: 2rem;
    font-size: 0.85rem;
    color: $placeholder-color;
  }

  &__list-item {
    line-height: 1.5rem;
    margin-bottom: 1rem;
  }

  &__list-icon {
    fill: $primary-color;
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.5rem;
  }

  &--selected &__list-icon {
    fill: $primary-white;
  }

  &--selected &__disclaimer {
    color: $primary-white;
  }
}

.radio {
  input[type="radio"] {
    position: absolute;
    opacity: 0;

    // + .radio-label {
    //   &:before {
    //     content: "";
    //     background: $primary-white;
    //     border-radius: 100%;
    //     border: 3px solid $primary-color;
    //     display: flex;
    //     width: 1.5rem;
    //     height: 1.5rem;
    //     position: relative;
    //     top: 0.25rem;
    //     cursor: pointer;
    //     transition: all 0.5s ease;
    //   }
    // }

    &:checked {
      + .radio-label {
        &:before {
          background-color: $primary-color;
          box-shadow: inset 0 0 0 3px $primary-white;
        }
      }
    }

    &:focus {
      + .radio-label {
        &:before {
          outline: none;
          border-color: $primary-color;
        }
      }
    }

    &:disabled {
      + .radio-label {
        &:before {
          box-shadow: inset 0 0 0 3px $primary-white;
          border-color: $primary-white;
          background: $primary-white;
        }
      }
    }

    + .radio-label {
      &:empty {
        &:before {
          margin-right: 0;
        }
      }
    }
  }
}
