.braintree {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    &__logo {
        margin-left: auto;
        max-width: 8rem;
    }
}