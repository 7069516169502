.message-icon-wrapper {
  display: inline-block;
  width: 186px;
}

.message-icon {
  width: 100%;
}

.message-block {
  width: 516px;
  position: absolute;
  display: flex;
  gap: 2rem;
  top: 50%;
  right: 50%;
  -ms-transform: translate(50%, -50%);
  -webkit-transform: translate(50%, -50%);
  transform: translate(50%, -50%);
}
.message-block__content {
  width: 272px;
}
.message-block__main-text {
  color: #99a4ae;
  font-family: "Acme", sans-serif;
  font-size: 96px;
  margin-bottom: 22px;
}
.message-block__text {
  color: #677d88;
  font-family: "PT Sans", sans-serif;
  font-size: 20px;
  margin-bottom: 12px;
}
.message-block__link {
  color: #3274ba;
  display: inline-block;
}
.message-block__link:hover,
.message-block__link:focus {
  color: #3274ba;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}
.message-block__link:hover svg,
.message-block__link:focus svg {
  fill: #3274ba;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}
.message-block__link-icon {
  display: inline-block;
  margin-right: 8px;
}
.message-block__link-icon svg {
  fill: #3274ba;
}
.message-block__link-text {
  display: inline-block;
  font-family: "PT Sans", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 1;
}
